/* actionBar Active Animations */
#actionBar a div {
  transition: width 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), background-color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#actionBar a div svg {
    transform: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
}
#actionBar a span {
    transition: opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1)
}
#actionBar a.active div {
    background-color: #f3e8e8;
    width: 4rem;
}

#actionBar a.active span {
    color: #d10c33;
    opacity: 1;
}
